/**
 * アプリ起動時に読み込むマスタ情報のStore
 *
 * セレクトボックスなどで参照する
 */
import { IBank, IRole, IUserTypr, ISelectItem } from '@/types/master';
import { IRolePermission } from '@/types';

interface State {
  loaded: boolean;
  banks: IBank[];
  roles: IRole[];
  userTypes: IUserTypr[];
  selBanks: ISelectItem[];
  selBanksWdefault: ISelectItem[];
  selRole: ISelectItem[];
  selUserType: ISelectItem[];
  selTopicStatus: ISelectItem[];
  selReplyStatus: ISelectItem[];
  selSupplementStatus: ISelectItem[];
  selForumCategory: ISelectItem[];
}

// 権限デフォルト値
const defaultPermissions: IRolePermission = {
  'project-ba-bank': 0,
  'project-ba-invoice': 0,
  'project-ba-user': 0,
  'project-ba-changelog': 0,
  'project-forum-topic': 0,
  'project-forum-mail': 0,
  'project-subsidy-support-contact': 0,
  'setting-ba-config': 0,
  'tool-banner': 0,
  'tool-column': 0,
  'tool-media': 0,
  'admin-role': 0,
  'admin-user': 0,
  'dev-setting-app': 0,
  'dev-table-setting': 0,
  'dev-table-admin-file': 0,
  'dev-utils': 0
};

export const useMasterStore = (): any => {
  const master = useState<State>('master_store', () => ({
    loaded: false,
    banks: [
      {
        bank_code: '',
        bank_unique_code: '',
        bank_name: '',
        bank_xba_url: '',
        bank_ba_name: '',
        bank_system_code: '',
        bank_website_code: '',
        bank_shares_register_channel: '',
        bank_hold_on_production: 0,
        bank_portal_name: '',
        bank_portal_url: ''
      }
    ],
    roles: [
      {
        id: null,
        name: '',
        permissions: {
          'project-ba-bank': 0,
          'project-ba-invoice': 0,
          'project-ba-user': 0,
          'project-ba-changelog': 0,
          'project-forum-topic': 0,
          'project-forum-mail': 0,
          'project-subsidy-support-contact': 0,
          'setting-ba-config': 0,
          'tool-banner': 0,
          'tool-column': 0,
          'tool-media': 0,
          'admin-role': 0,
          'admin-user': 0,
          'dev-setting-app': 0,
          'dev-table-setting': 0,
          'dev-table-admin-file': 0,
          'dev-utils': 0
        },
        remarks: '',
        created_at: '',
        updated_at: '',
        updated_user: ''
      }
    ],
    userTypes: [
      {
        user_type: '',
        user_type_is_disabled: 0,
        user_type_name: '',
        user_role: 0,
        user_is_super_user: null,
        user_type_order: 0,
        user_type_group: '',
        user_can_use_support_chat: 0,
        user_restricts_companies_under_my_branch: 0
      }
    ],
    selBanks: [
      {
        value: '',
        title: ''
      }
    ],
    selBanksWdefault: [
      {
        value: '',
        title: ''
      }
    ],
    selRole: [
      {
        value: 0,
        title: ''
      }
    ],
    selUserType: [
      {
        value: '',
        title: ''
      }
    ],
    selTopicStatus: [
      {
        value: '',
        title: ''
      }
    ],
    selReplyStatus: [
      {
        value: '',
        title: ''
      }
    ],
    selSupplementStatus: [
      {
        value: '',
        title: ''
      }
    ],
    selForumCategory: [
      {
        value: '',
        title: ''
      }
    ]
  }));

  // 一覧共通モジュールを利用
  const { loadDataWoState } = Search();

  const loadMaster = async (): Promise<any> => {
    const config = useRuntimeConfig();

    // 金融機関マスタ
    const promiseBanks: any = loadDataWoState('/master/banks?demo=1', {}, 'get', true, true, true);

    // ロールマスタ
    const promiseRoles: any = loadDataWoState('/admin/roles', {}, 'get', true, true, true);

    // ユーザータイプマスタ
    const promiseUserType: any = loadDataWoState('/master/user_types', {}, 'get', true, true, true);

    // 掲示板相談ステータスマスタ
    const promiseTopicStatus: any = loadDataWoState(
      `${config.public.bamBaseUrl}/forum/api/v1/manager/topic/status`,
      {},
      'get',
      true,
      true,
      true
    );

    // 掲示板回答ステータスマスタ
    const promiseReplyStatus: any = loadDataWoState(
      `${config.public.bamBaseUrl}/forum/api/v1/manager/reply/status`,
      {},
      'get',
      true,
      true,
      true
    );

    // 掲示板補足ステータスマスタ
    const promiseSupplementStatus: any = loadDataWoState(
      `${config.public.bamBaseUrl}/forum/api/v1/manager/supplement/status`,
      {},
      'get',
      true,
      true,
      true
    );

    // 掲示板相談カテゴリーマスタ
    const promiseForumCategory: any = loadDataWoState(
      `${config.public.bamBaseUrl}/forum/api/v1/master/categories`,
      {},
      'get',
      true,
      true,
      true
    );

    const [banks, roles, userTypes, topicStatus, replyStatus, supplementStatus, forumCategory] = await Promise.all([
      promiseBanks,
      promiseRoles,
      promiseUserType,
      promiseTopicStatus,
      promiseReplyStatus,
      promiseSupplementStatus,
      promiseForumCategory
    ]);
    if (banks && banks.result) {
      master.value.banks = banks.result.list;

      // セレクトボックス用金融機関
      master.value.selBanks = banks.result.list.map((item: any) => ({
        value: item.bank_code,
        title: `${item.bank_code}：${item.bank_name} (${item.bank_system_code})`
      }));

      master.value.selBanksWdefault = [{ value: '0000', title: '0000：共通' }, ...master.value.selBanks];

      // structuredClone is not definedにならないようclientのみの実行とする
      if (process.client && roles && roles.result) {
        // ロールマスタ
        roles.result.list.forEach(function (item: any, index: number) {
          const tmp = structuredClone(item);
          tmp.permissions = { ...defaultPermissions, ...item.permissions };
          master.value.roles[index] = tmp;
        });

        // セレクトボックス用ロール
        master.value.selRole = roles.result.list.map((item: any) => ({
          value: item.id,
          title: item.name
        }));
      }
    }
    // ユーザータイプマスタ
    if (userTypes && userTypes.result) {
      master.value.userTypes = userTypes.result.list;

      // セレクトボックス用ユーザータイプ
      master.value.selUserType = userTypes.result.list.map((item: any) => ({
        value: item.user_type,
        title: item.user_type_name
      }));
    }

    // 掲示板相談ステータス
    if (topicStatus && topicStatus.result) {
      master.value.selTopicStatus = topicStatus.result.list.map((item: any) => ({
        value: item.code,
        title: item.message
      }));
    }

    // 掲示板回答ステータス
    if (replyStatus && replyStatus.result) {
      master.value.selReplyStatus = replyStatus.result.list.map((item: any) => ({
        value: item.code,
        title: item.message
      }));
    }

    // 掲示板補足ステータス
    if (supplementStatus && supplementStatus.result) {
      master.value.selSupplementStatus = supplementStatus.result.list.map((item: any) => ({
        value: item.code,
        title: item.message
      }));
    }

    // 掲示板相談カテゴリ
    if (forumCategory && forumCategory.result) {
      master.value.selForumCategory = forumCategory.result.list.map((item: any) => ({
        value: item.code,
        title: item.name
      }));
    }

    master.value.loaded = true;
    return true;
  };

  // ロールマスタのロード
  const loadMasterRole = async (): Promise<void> => {
    master.value.loaded = false;

    // ロールマスタ
    const roles: any = await loadDataWoState('/admin/roles', {}, 'get', true, true, true);

    if (roles && roles.result) {
      // ロールマスタ
      roles.result.list.forEach(function (item: any, index: number) {
        const tmp = structuredClone(item);
        tmp.permissions = { ...defaultPermissions, ...item.permissions };
        master.value.roles[index] = tmp;
      });

      // セレクトボックス用ロール
      master.value.selRole = roles.result.list.map((item: any) => ({
        value: item.id,
        title: item.name
      }));
    }
    master.value.loaded = true;
  };

  return {
    master,
    loadMaster,
    loadMasterRole
  };
};
